<template>
  <div class="page-box">
    <div class="h-list">
      <div class="h-item head">
        <div class="inner">
          <div class="index">#</div>
          <div class="img"></div>
          <div class="name">用户</div>
          <div class="num">应助数</div>
        </div>
      </div>
      <div class="h-item" v-for="(item, index) in helperList" :key="item.id">
        <div class="inner">
          <div class="index">{{index+1}}</div>
          <div class="img"><img :src="item.headimgurl" alt=""></div>
          <div class="name">{{item.nickname}}</div>
          <div class="num">{{item.helpNum}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  data () {
    return {
      helperList: []
    }
  },
  mounted () {
    document.title = '应助排行榜'
    this.loadHelperList()
  },
  methods: {
    loadHelperList () {
      this.$http.get(`${this.httpRoot}/helper/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.helperList = res.helperList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
<style lang="less">
.h-list {
  .h-item {
    padding: 5px 0;
    &.head {
      background-color: #e9eaef;
      border-bottom: 1px solid #ddd;
    }
    .inner {
      display: flex;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
    .index {
      width: 50px;
      text-align: center;
    }
    .img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      margin-left: 20px;
      width: 250px;
    }
  }
}
.h-list .h-item:nth-child(2n) {
  background: #fafafa;
}
</style>
